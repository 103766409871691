<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <span>{{ computedSetTitle }}</span>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal(), resetForm()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá antes de {{ computedSubTitle }}, descreva um motivo abaixo!</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Informações de Cancelamento</span>
        </div>
      </div>

      <v-form ref="form">
        <v-textarea
          v-model="cancellationDescription"
          class="mt-6"
          label="Motivo do Cancelamento"
          :rules="[rules.required]"
          outlined
        />
      </v-form>

      <div class="container-center">
        <v-btn
          v-if="data.status === 'AGUARDANDO RH'"
          width="140px"
          color="error"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex"
            style="gap: 10px;"
          >
            <span>
              Cancelar
            </span>

            <v-img
              src="../../../../../assets/images/svg/cancel-circle.svg"
              width="20"
              height="20"
              class="me-2"
            />
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>

        <v-btn
          v-if="['AGUARDANDO RD', 'AGUARDANDO DIRETORIA'].includes(data.status)"
          color="error"
          min-height="40px"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <v-icon
              medium
            >
              {{ icons.mdiThumbDownOutline }}
            </v-icon>

            <span>RECUSAR</span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiClose, mdiThumbDownOutline } from '@mdi/js'

export default {
  mixins: [messages, formatters],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoadingSendData: false,
      showModalCancelConfirm: false,
      cancellationDescription: '',

      icons: {
        mdiClose,
        mdiThumbDownOutline,
      },
    }
  },

  computed: {
    computedSetTitle() {
      const statusMap = {
        'AGUARDANDO RD': 'Recusar Solicitação',
        'AGUARDANDO DIRETORIA': 'Recusar Solicitação',
      }

      const { status = '' } = this.data

      return statusMap[status] || 'Cancelar Solicitação'
    },

    computedSubTitle() {
      const statusMap = {
        'AGUARDANDO RD': 'recusar',
        'AGUARDANDO DIRETORIA': 'recusar',
      }

      const { status = '' } = this.data

      return statusMap[status] || 'cancelar'
    },
  },

  methods: {
    async sendModalData() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        this.showMessage({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios',
        })

        return
      }

      const confirmAction = await this.confirmAction()

      if (!confirmAction) return

      const body = {
        status: 'CANCELADO',
        cancellation_reason: this.cancellationDescription,
      }

      this.isLoadingSendData = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.showMessage({
          icon: 'success',
          title: 'Requisição cancelada com sucesso',
        })
        this.resetForm()
        this.updatedTable()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendData = false
      }
    },

    /**
     * Confirms an action with a warning message.
     *
     * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the action was confirmed.
     */
    async confirmAction() {
      const result = await this.$swal({
        title: 'Tem certeza?',
        text: 'Ao clicar em sim, o processo será cancelado e não poderá ser mais editado.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },

    resetForm() {
      this.$refs.form.reset()
    },

    closeModal() {
      this.$emit('closeModalCancel')
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
