<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div
          class="d-flex justify-center mb-4"
          style="width: 100%;"
        >
          <h4 style="font-size: 20px">
            Desligamento de Funcionário
          </h4>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <v-tabs
        v-model="selectedTab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.icon"
          class="tabs-just"
          :disabled="tab.disabled"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <modal-details
            :key="data.id"
            :data="data"
            :without-header="true"
            :user-profile="userProfile"
            @closeModalDetails="toggleModal('details', false)"
            @updatedTable="updatedTable()"
          ></modal-details>
        </v-tab-item>

        <v-tab-item :disabled="data.status === 'AGUARDANDO DP'">
          <Validations
            :key="data.id"
            :data="data"
            :user-profile="userProfile"
            @previous-tab="previousTab()"
            @updatedTable="updatedTable()"
          ></Validations>
        </v-tab-item>

        <v-tab-item>
          <results
            :key="data.id"
            :data="data"
            :user-profile="userProfile"
            @previous-tab="previousTab()"
            @updatedTable="updatedTable()"
          ></results>
        </v-tab-item>
      </v-tabs-items>

      <div
        v-if="showNavigationContainer"
        class="d-flex justify-space-between"
      >
        <v-btn
          :disabled="selectedTab === 0"
          outlined
          @click="previousTab()"
        >
          <div
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>
              Anterior
            </span>
          </div>
        </v-btn>

        <v-btn
          color="info"
          @click="advanceTab()"
        >
          <div
            class="d-flex align-center"
            style="gap: 10px;"
          >
            <v-icon>{{ icons.mdiArrowRight }}</v-icon>

            <span>
              Proximo
            </span>
          </div>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

import {
  mdiAccountOutline, mdiAlertCircleOutline,
  mdiArrowLeft, mdiArrowRight,
  mdiCheckCircleOutline, mdiClose,
} from '@mdi/js'

import ModalDetails from './tabs/ModalDetails.vue'
import Results from './tabs/Results.vue'
import Validations from './tabs/Validations.vue'

export default {
  components: {
    ModalDetails,
    Validations,
    Results,
  },

  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },

    userProfile: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showModalCancel: false,
      showModalDP: false,
      showModalRD: false,

      selectedTab: '',

      tabs: [
        { title: 'Requisição', icon: mdiAccountOutline, disabled: false },
        { title: 'Validação', icon: mdiAlertCircleOutline, disabled: false },
        { title: 'Resultados', icon: mdiCheckCircleOutline, disabled: false },
      ],

      icons: {
        mdiClose,
        mdiArrowLeft,
        mdiArrowRight,
      },
    }
  },

  computed: {
    showNavigationContainer() {
      const statuses = ['AGUARDANDO DP', 'AGUARDANDO RD', 'AGUARDANDO DIRETORIA']
      const directorStatus = ['AGUARDANDO RD', 'AGUARDANDO DIRETORIA']

      if (directorStatus.includes(this.data.status) && this.selectedTab === 0) {
        return true
      }

      return !statuses.includes(this.data.status) && this.selectedTab !== 2
    },
  },

  created() {
    this.setDisabledTabs()
  },

  methods: {
    setDisabledTabs() {
      this.tabs[1].disabled = this.data.status === 'AGUARDANDO DP'
      this.tabs[2].disabled = this.data.status === 'AGUARDANDO DP'
    },

    previousTab() {
      if (this.selectedTab > 0) {
        this.selectedTab -= 1
      }
    },

    advanceTab() {
      const lastTabIndex = this.tabs.length - 1
      this.selectedTab = Math.min(this.selectedTab + 1, lastTabIndex)
    },

    toggleModal(type, isOpen) {
      const typeMap = {
        cancel: () => {
          this.showModalCancel = isOpen
        },
        sendDP: () => {
          this.showModalDP = isOpen
        },
        sendRD: () => {
          this.showModalRD = isOpen
        },
      }

      if (type in typeMap) {
        typeMap[type]()
      }
    },

    closeModal() {
      this.$emit('closeModalRD')
    },
  },
}
</script>

<style scoped>
.head-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator {
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label {
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center {
  display: flex;
  justify-content: center;
}

.container-files {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 10px;

  border: 0.2px solid rgba(202, 201, 205, 0.267);
  border-radius: 5px;
}

.document-label {
  display: flex;
  position: relative;
  top: 10px;
  left: 12px;
  z-index: 1;
  background: #312d4b;
  width: fit-content;
  font-size: 12px;
}
</style>
