<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <span>Enviar para DP</span>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal(), resetForm()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, antes de enviar para DP, preencha os campos abaixo!</span>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <span>Dados da Função</span>
        </div>
      </div>

      <v-form ref="form">
        <v-textarea
          v-model="historyDescription"
          class="mt-6"
          label="Descrição do histórico de desempenho"
          :rules="[rules.required]"
          outlined
        />

        <v-file-input
          v-model="files"
          class="mt-1"
          label="Inserir Documentos"
          multiple
          outlined
          dense
          chips
          :append-icon="icons.mdiPaperclip"
          :prepend-icon="null"
          :rules="[rules.required]"
        />
      </v-form>

      <div class="container-center mt-1">
        <v-btn
          color="success"
          width="180px"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex align-center"
          >
            <v-img
              src="../../../../../assets/images/svg/arrow-right.svg"
              width="25"
              max-height="20"
              class="me-2"
            />

            <span>
              Enviar para DP
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiClose, mdiPaperclip } from '@mdi/js'

export default {
  mixins: [formatters, messages, utilsMixin],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      files: [],
      historyDescription: '',

      isLoadingSendData: false,

      icons: {
        mdiClose,
        mdiPaperclip,
      },
    }
  },

  methods: {
    async sendModalData() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        this.showMessage({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios',
        })

        return
      }

      const confirmAction = await this.confirmAction()

      if (!confirmAction) return

      const body = {
        status: 'AGUARDANDO DP',
        rh_observations: this.historyDescription,
      }

      this.isLoadingSendData = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.showMessage({
          icon: 'success',
          title: 'Solicitação enviada!',
        })

        this.updatedTable()

        if (this.files.length > 0) {
          this.sendModalFiles()
        }

        this.resetForm()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendData = false
      }
    },

    async sendModalFiles() {
      try {
        await this.sendFiles('api/v1/sgq/attachments_shutdowns_rh/submit_files', this.files, { id: this.data.id })
      } catch (error) {
        this.showMessage({ icon: 'error', title: 'Erro ao enviar os documentos' })
      }
    },

    /**
     * Confirms an action with a warning message.
     *
     * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the action was confirmed.
     */
    async confirmAction() {
      const result = await this.$swal({
        title: 'Tem certeza?',
        text: 'Ao clicar em sim, será confirmado o andamento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },

    resetForm() {
      this.$refs.form.reset()
    },

    closeModal() {
      this.$emit('closeModalDP')
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
