<template>
  <div>
    <v-card class="px-6 py-6">
      <div class="head-modal">
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <span>Enviar para RD</span>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal(), resetForm()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4 mb-4">
        <span>👋 Olá, antes de enviar para RD, preencha os campos abaixo!</span>
      </div>

      <v-form ref="form">
        <div
          class="d-flex"
          style="gap: 20px;"
        >
          <v-text-field
            v-model="totalVale"
            :prepend-inner-icon="icons.mdiCurrencyUsd"
            label="Vales"
            type="number"
            outlined
            dense
          />

          <v-text-field
            v-model="totalDuplicate"
            label="Duplicatas"
            :prepend-inner-icon="icons.mdiCurrencyUsd"
            type="number"
            outlined
            dense
          />
        </div>

        <div
          class="d-flex align-center"
          style="gap: 50px;"
        >
          <v-radio-group
            v-model="isLoanActive"
            mandatory
          >
            <p style="font-size: 16px">
              O funcionário tem empréstimos ativo?
            </p>

            <div
              class="d-flex align-baseline"
              style="gap: 20px;"
            >
              <v-radio
                label="Sim"
                value="SIM"
                color="#9C43FA"
              />

              <v-radio
                label="Não"
                value="NÃO"
                color="#9C43FA"
              />
            </div>
          </v-radio-group>

          <v-text-field
            v-model="totalLoan"
            :prepend-inner-icon="icons.mdiCurrencyUsd"
            label="Empréstimo Assomotoca"
            :rules="isLoanActive === 'SIM' ? [rules.required] : []"
            :disabled="isLoanActive === 'NÃO'"
            outlined
            dense
          />
        </div>

        <v-textarea
          v-model="observations"
          label="Observações"
          outlined
        />
      </v-form>

      <div class="container-center mt-1">
        <v-btn
          color="success"
          width="180px"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex align-center"
          >
            <v-img
              src="../../../../../assets/images/svg/arrow-right.svg"
              width="25"
              max-height="20"
              class="me-2"
            />

            <span>
              Enviar para RD
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiClose, mdiCurrencyUsd } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      observations: '',
      totalVale: '',
      totalDuplicate: '',
      totalLoan: '',

      isLoanActive: 'NÃO',
      isLoadingSendData: false,

      icons: {
        mdiClose,
        mdiCurrencyUsd,
      },
    }
  },

  methods: {
    async sendModalData() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        this.showMessage({
          icon: 'error',
          title: 'Preencha todos os campos obrigatórios',
        })

        return
      }

      const confirmAction = await this.confirmAction()

      if (!confirmAction) return

      const body = {
        status: 'AGUARDANDO RD',
        dp_observations: this.observations,
        total_vale: this.totalVale,
        total_duplicate: this.totalDuplicate,
        total_loan: this.totalLoan,
      }

      this.isLoadingSendData = true

      try {
        await axiosIns.put(`api/v1/sgq/shutdown/update/${this.data.id}`, body)

        this.showMessage({
          icon: 'success',
          title: 'Solicitação enviada!',
        })

        this.updatedTable()
        this.resetForm()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendData = false
      }
    },

    /**
     * Confirms an action with a warning message.
     *
     * @return {Promise<boolean>} A promise that resolves to a boolean indicating if the action was confirmed.
     */
    async confirmAction() {
      const result = await this.$swal({
        title: 'Tem certeza?',
        text: 'Ao clicar em sim, será confirmado o andamento.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      })

      return result.isConfirmed
    },

    async resetForm() {
      this.$refs.form.reset()
      await this.$nextTick()

      this.isLoanActive = 'NÃO'
    },

    closeModal() {
      this.$emit('closeModalRD')
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.container-center{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
