<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div
          class="d-flex justify-center"
          style="width: 100%;"
        >
          <h4 style="font-size: 20px">
            Desligamento de Funcionário
          </h4>
        </div>

        <v-icon
          medium
          class="me-2"
          @click="closeModal(), resetForm()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4 d-flex justify-center">
        <h4 style="font-size: 15px">
          👋 Olá, para requisitar, basta preencher os campos abaixo!
        </h4>
      </div>

      <div class="separator">
        <div
          class="label"
        >
          <h4 style="font-size: 15px">
            Justificativa
          </h4>
        </div>
      </div>

      <v-form ref="form">
        <v-autocomplete
          v-model="selectedEmployeeId"
          class="pt-6"
          :items="employees"
          label="Funcionário"
          item-text="name"
          item-value="id"
          :rules="[rules.required]"
          outlined
          dense
        />

        <div class="line mt-0">
          <v-autocomplete
            v-model="selectedShutdownType"
            :items="shutdownType"
            label="Tipo de desligamento"
            :rules="[rules.required]"
            outlined
            dense
          />

          <v-autocomplete
            v-model="selectedShutdownWarningType"
            :items="warningType"
            label="Tipo de aviso"
            :rules="[rules.required]"
            outlined
            dense
          />

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="isoDate"
            transition="scale-transition"
            offset-y
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-text-field
                v-model="formattedDate"
                label="Data"
                :rules="[rules.required]"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-if="menu"
              v-model="isoDate"
              locale="pt-BR"
              @change="(date) => ($refs.menu.save(date), (isoDate = date))"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-textarea
          v-model="reasonShutdownDescription"
          label="Descrição do motivo de desligamento"
          outlined
        />

        <div>
          <p>Foi realizada alguma medida preventiva?</p>

          <v-radio-group
            v-model="preventiveMeasureTaken"
            :rules="[rules.required]"
            row
          >
            <v-radio
              label="Sim"
              value="SIM"
              color="#9C43FA"
            />

            <v-radio
              label="Não"
              value="NÃO"
              color="#9C43FA"
            />
          </v-radio-group>
        </div>

        <v-textarea
          v-model="preventiveMeasureDescription"
          label="Quais?"
          outlined
          :rules="preventiveMeasureTaken === 'SIM' ? [rules.required] : []"
          :disabled="preventiveMeasureTaken === 'NÃO'"
        />

        <div>
          <p>É necessário substituição?</p>

          <v-radio-group
            v-model="replacementNeeded"
            :rules="[rules.required]"
            row
          >
            <v-radio
              label="Sim"
              value="SIM"
              color="#9C43FA"
            />

            <v-radio
              label="Não"
              value="NÃO"
              color="#9C43FA"
            />

            <v-radio
              label="Indeterminado"
              value="INDETERMINADO"
              color="#9C43FA"
            />
          </v-radio-group>
        </div>
      </v-form>

      <div
        class="container-center"
      >
        <v-btn
          color="info"
          :disabled="isLoadingSendData"
          @click="sendModalData()"
        >
          <div
            v-if="!isLoadingSendData"
            class="d-flex"
          >
            <v-img
              src="../../../../../assets/images/svg/account-arrow-up.svg"
              width="20"
              height="20"
              class="me-2"
            />

            <span>
              Solicitar
            </span>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiClose } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      disabledInputEmployee: false,
      loadingSubmitRequest: false,

      employees: [],
      selectedEmployeeId: '',
      selectedShutdownType: '',
      selectedShutdownWarningType: '',
      reasonShutdownDescription: '',
      preventiveMeasureTaken: '',
      preventiveMeasureDescription: '',
      replacementNeeded: '',

      menu: false,
      isoDate: '',
      dateLabel: 'Data',

      isLoadingSendData: false,

      icons: {
        mdiClose,
      },

      shutdownType: [
        'ACORDO LEGAL',
        'DISPENSA',
        'PEDIDO',
        'OUTROS',
      ],
      warningType: [],

    }
  },

  computed: {
    formattedDate: {
      get() {
        if (!this.isoDate) return ''

        const [year, month, day] = this.isoDate.split('-')

        return `${day}/${month}/${year}`
      },

      set(value) {
        if (!value) {
          this.isoDate = value

          return
        }

        const [day, month, year] = value.split('/')
        this.isoDate = `${year}-${month}-${day}`
      },
    },
  },

  watch: {
    selectedShutdownType() {
      this.getDateLabel()

      this.getValuesWarningType()
    },
  },

  created() {
    this.employees = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    async sendModalData() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        this.showMessage({ icon: 'error', title: 'Campos Obrigatórios', text: 'Preencha todos os campos obrigatórios' })

        return
      }

      const body = {
        shutdown_type: this.selectedShutdownType,
        status: 'AGUARDANDO RH',
        reason_dismissal: this.reasonShutdownDescription,
        preventive_measures: this.preventiveMeasureDescription,
        substitution: this.replacementNeeded,
        notice_date: this.isoDate,
        employee_id: this.selectedEmployeeId,
        notice_type: this.selectedShutdownWarningType,
      }

      this.isLoadingSendData = true

      try {
        await axiosIns.post('api/v1/sgq/shutdown/store', body)

        this.showMessage({
          icon: 'success',
          title: 'Requisição feita com sucesso!',
          text: 'Os registros foram enviados!',
        })

        this.resetForm()
        this.updatedTable()
      } catch (error) {
        this.showErrorMessage(error)
      } finally {
        this.isLoadingSendData = false
        this.closeModal()
      }
    },

    getDateLabel() {
      const labelMap = {
        DISPENSA: 'da dispensa',
        PEDIDO: 'do pedido',
        'AVISO TRABALHADO': 'do aviso',
        'AVISO INDENIZADO': 'do aviso',
        'ACORDO LEGAL': 'do acordo',
      }

      this.dateLabel = 'Data '.concat(labelMap[this.selectedShutdownType] ?? '')
    },

    getValuesWarningType() {
      if (this.selectedShutdownType === 'DISPENSA') {
        this.warningType = [
          'INDENIZADO',
          'JUSTA  CAUSA',
          'TRABALHADO',
        ]
      } else {
        this.warningType = [
          'INDENIZADO',
          'TRABALHADO',
        ]
      }
    },

    resetForm() {
      this.$refs.form.reset()
    },

    closeModal() {
      this.$emit('closeModalCreate')
    },
  },
}
</script>

<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.separator{
  position: relative;
  border-top: 1px solid #cac9cd96;
  margin-top: 20px;
}

.separator .label{
  top: -22px;
  left: 50px;
  position: absolute;
  z-index: 100;
  background: #312d4b;
  padding: 8px;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: center;
}
</style>
